import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SectionMain from 'components/section-main';
import Img from 'gatsby-image';
import * as PhotoSwipe from 'photoswipe';
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import { Button } from './photogallery.css';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

class PhotoGallery extends PureComponent {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      var cont = document.createElement('div');
      cont.innerHTML = `
        <div class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="pswp__bg"></div>
            <div class="pswp__scroll-wrap">
                <div class="pswp__container">
                    <div class="pswp__item"></div>
                    <div class="pswp__item"></div>
                    <div class="pswp__item"></div>
                </div>
                <div class="pswp__ui pswp__ui--hidden">
                    <div class="pswp__top-bar">
                        <div class="pswp__counter"></div>
                        <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
                        <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
                        <div class="pswp__preloader">
                            <div class="pswp__preloader__icn">
                              <div class="pswp__preloader__cut">
                                <div class="pswp__preloader__donut"></div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                        <div class="pswp__share-tooltip"></div> 
                    </div>
                    <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
                    </button>
                    <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
                    </button>
                    <div class="pswp__caption">
                        <div class="pswp__caption__center"></div>
                    </div>
                </div>
            </div>
        </div>
      `;
      document.body.appendChild(cont);
    }

    this.pswpElement = document.querySelectorAll('.pswp')[0];

    this.items = this.props.items.map(item => ({
      src: item.node.childImageSharp.fluid.src,
      w: item.node.childImageSharp.fluid.presentationWidth,
      h: item.node.childImageSharp.fluid.presentationHeight,
    }));
  }

  openImage(imageIndex) {
    var gallery = new PhotoSwipe(
      this.pswpElement,
      PhotoSwipeUI_Default,
      this.items,
      {
        index: imageIndex,
        getThumbBoundsFn: function(index) {
          var thumbnail = document.querySelectorAll('.galleryThumb')[index];

          var pageYScroll =
            window.pageYOffset || document.documentElement.scrollTop;
          var rect = thumbnail.getBoundingClientRect();

          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        },
        shareEl: false,
        captionEl: false,
        fullscreenEl: false,
      }
    );
    gallery.init();
  }

  render() {
    const { items } = this.props;

    return (
      <>
        <SectionMain
          theme={{
            gridColumns: '1fr 1fr 1fr 1fr 1fr',
            gridColumnsMobile: '1fr 1fr',
          }}
        >
          {items.map((node, i) => (
            <Button key={i} onClick={() => this.openImage(i)}>
              <Img
                fluid={node.node.childImageSharp.fluid}
                sizes={node.sizes}
                className="galleryThumb"
              />
            </Button>
          ))}
        </SectionMain>
      </>
    );
  }
}

PhotoGallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PhotoGallery;
