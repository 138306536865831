import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Head from 'components/head';
import PhotoGallery from 'components/photogallery';
import SectionMainHead from 'components/section-main-head';

const Gallery = ({ data }) => {
  const PAGE = data.galleryJson;

  return (
    <>
      <Head pageTitle={PAGE.title} />

      <SectionMainHead data={PAGE} />

      <PhotoGallery items={data.allFile.edges} />
    </>
  );
};

Gallery.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Gallery;

export const query = graphql`
  query GalleryQuery {
    galleryJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
    allFile(
      filter: {
        absolutePath: { regex: "/content/gallery/" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      totalCount
      edges {
        node {
          name
          childImageSharp {
            fluid(
              maxWidth: 1280
              sizes: "(max-width: 1023px) 50vw, (max-width: 1213px) 180px"
            ) {
              presentationWidth
              presentationHeight
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
