import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Button = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
`;

export const PreviewWrapper = styled.div`
  position: relative;
  display: flex;
  overflow: scroll;
  padding-top: 30px;

  > div {
    width: 40%;
    margin-right: 30px;
    min-width: 220px;
    max-height: 240px;

    ${MEDIA.MIN_DESKTOP`
      min-width: 300px;
    `}
  }
`;

export const PreviewButton = styled.div`
  margin-top: 30px;

  ${MEDIA.MIN_DESKTOP`
      grid-column-start: 2;
  `}

  a {
    width: 100%;

    ${MEDIA.MIN_DESKTOP`
      max-width: 40%;
    `}
  }
`;
